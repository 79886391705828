import { useTranslation } from 'react-i18next'

import { Item } from '../../storybook/Aside/Item/Item';

export const Languages = () => {
  const { t, i18n } = useTranslation()

  const languages = [
    {
      'language_code': 'en',
      'name': t('English'),
    },
    {
      'language_code': 'ja',
      'name': t('日本語'),
    },
    {
      'language_code': 'zh-hant',
      'name': t('繁體中文'),
    },
    {
      'language_code': 'zh-hans',
      'name': t('简体中文'),
    },
  ]

  return languages.map((language, index) => {
    if (i18n.language != language['language_code']) {
      const clicked = () => {
        window.history.pushState("", "", window.location.href.replace(/\/(en|ja|zh-hant|zh-hans)+/, `/${language['language_code']}`))
        i18n.changeLanguage(language['language_code'])
      }

      return (
        <Item
          key={ index }
          name={ language['name'] }
          theme='blue'
          action='customize'
          onclick_action={ clicked }
        />
      )
    }
  })
}