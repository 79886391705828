import axios from 'axios'

export const custom_axios = axios.create()
if (localStorage.getItem("user")) {
  let token = `Bearer ${JSON.parse(localStorage.getItem("user")!)['access']}`
  custom_axios.defaults.headers.common['Authorization'] = token
}

custom_axios.interceptors.response.use(
  function (response) {
    return response
  }, function (error) {
    if (error.response?.status === 401) {
      const language = window.location.pathname?.split('/')?.[1]
      window.location.href = `/${language}/login`
    }
    return error
    // TODO
    // return Promise.reject(error);
  }
)
