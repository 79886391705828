import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from '../../app/hooks'
import {
  useNavigate,
} from 'react-router-dom'

import {
  get_user,
  update_user,
} from '../../slice/userSlice'

import {
  custom_axios,
} from '../../axios'

export const Twitter = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(get_user)

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);

    custom_axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/setting/twitter`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response.data.user) {
        dispatch(update_user(response.data.user))
        navigate(`/${i18n.language}`)
      } else {
        alert(t('Error'))
      }
    })
    .catch((response) => {
      alert(t('Error'))
    })
  }

  if (user) {
    return (
      <section className='p-2.5 lg:p-5'>
        <form
          onSubmit={ submit }
          method="post">

          <h2 className="text-pink-400 border-0 border-b border-dashed border-pink-400 text-center pb-1 mb-4">
            { t('Twitter') }
          </h2>

          <input
            type='text'
            required
            defaultValue={ user?.twitter }
            className="block text-center p-2 bg-white border border-black border-solid text-black w-full"
            name='twitter' />

          <input
            type='submit'
            className="bg-rose-800 border-black border rounded block px-6 py-3 mx-auto mt-5 cursor-pointer text-white shadow-black shadow hover:bg-rose-600 active:translate-y-0.5 active:shadow-none"
            value={ t('Submit')! } />
        </form>
      </section>
    )
  } else {
    return (
      <h2 className='text-center text-red-300 mt-4'>{ t('Please login first') }</h2>
    )
  }
}