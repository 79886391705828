import React, {
  useEffect,
} from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
  useLocation,
} from 'react-router-dom'

export const NewPassword = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!location.state?.email || !location.state?.code) {
      navigate(
        `/${i18n.language}/reset_password`,
        { state: {} }
      )
    }
  }, [])
  
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);
    const form_value = Object.fromEntries(form_data)

    if (form_value['password'] === form_value['password_confirmation']) {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/reset_password/new_password`,
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        if (response.data.successed) {
          alert(t('Reset password successed'))
          navigate(
            `/${i18n.language}`,
            { state: {} }
          )
        } else {
          alert(t('Error. Please try again.'))
        }
      })
      .catch((response) => {
        alert(t('Error. Please try again.'))
      })
    } else {
      alert(t("Password doesn't match"))
    }
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <form
        onSubmit={ submit }
        method="post"
      >
        <input
          type='hidden'
          name='email'
          value={location.state?.email} />

        <input
          type='hidden'
          name='code'
          value={location.state?.code} />

        <h2
          className="text-pink-400 border-0 border-b border-dashed border-pink-400 text-center pb-1 mb-5">
          { t('New password') }
        </h2>
        <input
          type='password'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='password' />

        <h2
          className="text-pink-400 border-0 border-b border-dashed border-pink-400 text-center pb-1 mb-4">
          { t('New password confirmation') }
        </h2>
        <input
          type='password'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='password_confirmation' />

        <input
          type='submit'
          className="bg-rose-800 border-black border rounded block px-6 py-3 mx-auto mt-5 cursor-pointer text-white shadow-black shadow hover:bg-rose-600 active:translate-y-0.5 active:shadow-none"
          value={ t('Submit')! } />
      </form>

      <button
        onClick={ () => { navigate(`/${i18n.language}/login`) } }
        className="block cursor-pointer text-rose-400 mx-auto mt-6 text-ceter w-fit border-0 border-b border-dashed border-rose-400 px-2 pb-1 hover:text-rose-600 hover:border-b-rose-600">
        { t('Login') }
      </button>

      <button
        onClick={ () => { navigate(`/${i18n.language}/sign_up`) } }
        className="block cursor-pointer text-rose-400 mx-auto mt-4 text-ceter w-fit border-0 border-b border-dashed border-rose-400 px-2 pb-1 hover:text-rose-600 hover:border-b-rose-600">
        { t('Sign up') }
      </button>
    </section>
  )
}