import { useTranslation } from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'
import {
  useState,
} from 'react'
import {
  useAppDispatch,
} from '../../app/hooks'

import { UserType } from '../../slice/userSlice'
import { PostMenu } from '../PostMenu/PostMenu'
import { updateImages, updateVisible } from '../../slice/slideshowSlice'
import {
  Form,
} from '../Form/Form'

export interface TagType {
  id: number,
  name: string,
}

export interface PostType {
  id: number,
  title: string,
  type: string,
  url: string,
  tags: TagType[],
  description: string,
  image: string,
  image_url?: string,
  show_image_url: boolean,
  user: UserType,
  created_at: string,
  good_count: number,
  bad_count: number,
  is_pressing_good?: boolean,
  is_pressing_bad?: boolean,
}

interface PostProps {
  post: PostType,
  tag_onclick?: Function,
  show_icon?: boolean,
  update_record: Function,
  delete_record: Function,
}

export const Post = ({
  post,
  tag_onclick,
  show_icon = true,
  update_record,
  delete_record,
}: PostProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [form_showing, set_form_showing] = useState<boolean>(false)

  const tags = post.tags.map((tag, tag_index) => {
    const tag_clicked = () => {
      if(tag_onclick) {
        tag_onclick(tag.name)
      } else {
        navigate(`/${i18n.language}?tag=${tag.name}`)
      }
    }
    return (
      <button
        key={tag_index}
        onClick={ tag_clicked }
        className="block px-1.5 py-1 bg-emerald-700 mt-1.5 rounded border border-black mr-1.5 cursor-pointer text-white hover:bg-emerald-900">
        { tag.name }
      </button>
    )
  })

  const Image = () => {
    if (post.show_image_url) {
      // Temporary comment out. I'm afraid of braeking the law.
      // if (post.image_url) {
      //   return (
      //     <img
      //       className="cursor-pointer block mb-1 mt-1.5 mx-auto w-full border border-solid border-black"
      //       src={ post.image_url }
      //       onClick={() => {
      //         const images = [
      //           {
      //             src: `${post.image_url}`,
      //             alt: '',
      //           },
      //         ]

      //         dispatch(updateImages(images))
      //         dispatch(updateVisible(true))
      //       }}
      //     />
      //   )
      // }
    } else if (post.image) {
      return (
        <img
          className="cursor-pointer block mb-1 mt-1.5 mx-auto w-full border border-solid border-black"
          src={ `${process.env.REACT_APP_BACKEND_HOST}${post.image}` }
          onClick={() => {
            const images = [
              {
                src: `${process.env.REACT_APP_BACKEND_HOST}${post.image}`,
                alt: '',
              },
            ]

            dispatch(updateImages(images))
            dispatch(updateVisible(true))
          }}
        />
      )
    }
  }

  return (
    <div
      className="block text-black p-3 rounded bg-white h-full"
    >
      <Form
        showing={ form_showing }
        set_showing={ set_form_showing }
        post={ post }
        update_record={ update_record }
      />
      <div>
        <PostMenu
          post={ post }
          type={ post.type }
          good_count={ post.good_count }
          bad_count={ post.bad_count }
          show_icon={ show_icon }
          update_record={ update_record }
          delete_record={ delete_record }
          form_showing={ form_showing }
          set_form_showing={ set_form_showing }
        />
        <div className="flex flex-row items-center flex-wrap mt-1.5 mb-2.5">
          <div className='mr-2 mt-1'>{ t('Tags') }:</div>
          { tags }
        </div>
        <a
          href={ post.url }
          className='block w-fit underline mt-1 text-purple-800 hover:text-purple-900 hover:font-bold'
          target='_blank'>
          { post.title }
        </a>
        <Image />
      </div>
    </div>
  )
};
