import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  HelmetProvider
} from 'react-helmet-async'
import { Provider } from 'react-redux'
import { store } from './app/store';
import {
  BrowserRouter,
} from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

import "./i18n/config"
import '@hamary/slideshow/style'
import './index.scss'

import { Body } from './Body'

const container = document.getElementById('root')!
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID??''}>
          <div className='min-h-screen'>
            <BrowserRouter>
              <Body />
            </BrowserRouter>
          </div>
        </GoogleOAuthProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);
