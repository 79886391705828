import React, {
  useEffect,
  useState,
} from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
} from '../app/hooks'
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  UserType,
} from '../slice/userSlice'

export const Profile = () => {
  const { t, i18n } = useTranslation()
  const { user_id } = useParams()

  const [user, set_user] = useState<UserType>()
  const [post_count, set_post_count] = useState<number>(0)
  const [good_count, set_good_count] = useState<number>(0)

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/profile/${user_id}`,
      data: {},
      headers: {},
    })
    .then((response: any) => {
      set_user(response.data?.user)
      set_post_count(response.data?.post_count)
      set_good_count(response.data?.good_count)
    })
  }, [])

  return (
    <section className='p-2.5 lg:p-5'>
      <div className='flex flex-row justify-between items-start mt-3'>
        <div className='flex flex-row justify-start items-center gap-x-2.5'>
          <img
            src={ `${process.env.REACT_APP_BACKEND_HOST}${user?.icon}` }
            className="block w-14 h-14 rounded-full border border-solid border-silver" />
          <div>@ { user_id }</div>
        </div>

        <Link
          to={`/${i18n.language}/home/${user_id}`}
          className='block bg-sky-600 border-white border rounded px-5 py-2.5 cursor-pointer text-white shadow-black shadow hover:bg-sky-700 active:translate-y-0.5 active:shadow-none'>
          { t('Home') }
        </Link>
      </div>

      <div className='mt-5'>
        { user?.description.split('\n').map( (description, index) => {
          return (<React.Fragment key={ index }>{ description }<br /></React.Fragment>)
        }) }
      </div>

      <div className='flex flex-row justify-start items-center gap-x-2.5 mt-5'>
        <div className='text-rose-400'>{ t('Post') }:</div>
        <div>{ post_count }</div>
      </div>

      <div className='flex flex-row justify-start items-center gap-x-2.5 mt-3'>
        <div className='text-rose-400'>{ t('Good') }:</div>
        <div>{ good_count }</div>
      </div>

      { user?.twitter && (
        <div className='flex flex-row justify-start items-center gap-x-2.5 mt-3'>
          <div className='text-rose-400'>{ t('Twitter') }:</div>
          <a
            className="block cursor-pointer text-purple-400 hover:text-purple-300 underline"
            target="_blank"
            href={ user?.twitter }
          >
            { user?.twitter }
          </a>
        </div>
      ) }

      { user?.instagram && (
        <div className='flex flex-row justify-start items-center gap-x-2.5 mt-3'>
          <div className='text-rose-400'>{ t('Instagram') }:</div>
          <a
            className="block cursor-pointer text-purple-400 hover:text-purple-300 underline"
            target="_blank"
            href={ user?.instagram }
          >
            { user?.instagram }
          </a>
        </div>
      ) }

      { user?.facebook && (
        <div className='flex flex-row justify-start items-center gap-x-2.5 mt-3'>
          <div className='text-rose-400'>{ t('Facebook') }:</div>
          <a
            className="block cursor-pointer text-purple-400 hover:text-purple-300 underline"
            target="_blank"
            href={ user?.facebook }
          >
            { user?.facebook }
          </a>
        </div>
      ) }

      { user?.line && (
        <div className='flex flex-row justify-start items-center gap-x-2.5 mt-3'>
          <div className='text-rose-400'>{ t('Line') }:</div>
          <a
            className="block cursor-pointer text-purple-400 hover:text-purple-300 underline"
            target="_blank"
            href={ user?.line }
          >
            { user?.line }
          </a>
        </div>
      ) }
    </section>
  )
}
