import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from '../../app/hooks'
import {
  get_user,
} from '../../slice/userSlice'

import {
  useNavigate,
} from 'react-router-dom'

import {
  update_form_is_visible,
} from '../../slice/postSlice'

import { Item } from '../../storybook/AsideMobile/Item/Item';

interface FunctionsType {
  set_aside_mobile_is_open: Function,
}

export const Functions = (props: FunctionsType) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(get_user)

  const apps = [
    {
      'name': t('Home'),
      'path': (
        user
        ? `/${i18n.language}/home/${user?.id}`
        : `/${i18n.language}/login`
      ),
    },
    {
      'name': t('Post'),
      'action': 'customize',
      'onclick_action': () => {
        user
        ? dispatch(update_form_is_visible(true))
        : navigate(`/${i18n.language}/login`)
      }
    },
    {
      'name': t('Good'),
      'path': (
        user
        ? `/${i18n.language}/good`
        : `/${i18n.language}/login?next=/${i18n.language}/good`
      ),
    },
    {
      'name': t('Search'),
      'path': `/${i18n.language}`,
    }
  ]


  return apps.map((app, index) => {
    if (app['action'] == 'customize') {
      return (
        <Item
          key={ index }
          name={ app['name'] }
          path={ app['path'] }
          theme='red'
          action='customize'
          onclick_action={ app['onclick_action'] }
        />
      )
    } else {
      return (
        <Item
          key={ index }
          name={ app['name'] }
          path={ app['path'] }
          theme='red'
          action='navigate'
          set_aside_mobile_is_open={props.set_aside_mobile_is_open}
        />
      )
    }
  })
}