import React, {
  useEffect,
  useRef
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from '../../app/hooks'
import {
  useNavigate,
} from 'react-router-dom'

import {
  get_user,
  update_user,
} from '../../slice/userSlice'

import {
  custom_axios,
} from '../../axios'

export const Description = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(get_user)

  const textarea_ref = useRef<HTMLTextAreaElement|null>(null)

  useEffect(() => {
    if (textarea_ref.current) {
      textarea_ref.current.style.height = 'auto'
      textarea_ref.current.style.height = textarea_ref.current.scrollHeight + 'px';
    }
  }, [user])

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);

    custom_axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/setting/description`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response.data.user) {
        dispatch(update_user(response.data.user))
        navigate(`/${i18n.language}`)
      } else {
        alert(t('Error'))
      }
    })
    .catch((response) => {
      alert(t('Error'))
    })
  }

  const textarea_change_height = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = 'auto'
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  }

  if (user) {
    return (
      <section className='p-2.5 lg:p-5'>
        <form
          onSubmit={ submit }
          method="post">

          <h2 className="text-pink-400 border-0 border-b border-dashed border-pink-400 text-center pb-1 mb-4">
            { t('Self introduction') }
          </h2>

          <textarea
            required
            name='description'
            ref={ textarea_ref }
            onChange={ textarea_change_height }
            className='block bg-white p-3 border border-black border-solid text-black w-full'
            defaultValue={ user?.description } >
          </textarea>

          <input
            type='submit'
            className="bg-rose-800 border-black border rounded block px-6 py-3 mx-auto mt-5 cursor-pointer text-white shadow-black shadow hover:bg-rose-600 active:translate-y-0.5 active:shadow-none"
            value={ t('Submit')! } />
        </form>
      </section>
    )
  } else {
    return (
      <h2 className='text-center text-red-300 mt-4'>{ t('Please login first') }</h2>
    )
  }
}