import { createSlice } from '@reduxjs/toolkit'

export interface ImageType {
  src: string
  alt?: string
}

export interface SlideshowStateType {
  images: ImageType[]
  visible: boolean
}

export const slideshowInitialState: SlideshowStateType = {
  images: [],
  visible: false,
}

export const SlideshowSlice = (state?: SlideshowStateType) => {
  const initialState = state ?? slideshowInitialState

  return createSlice({
    name: 'slideshow',
    initialState,
    reducers: {
      updateImages: (state, action) => {
        state.images = action.payload
      },
      updateVisible: (state, action) => {
        state.visible = action.payload
        document.body.style.overflow = action.payload ? 'hidden' : ''
      },
    },
  })
}

export const { updateImages, updateVisible } = SlideshowSlice().actions
export default SlideshowSlice().reducer
