import { useTranslation } from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'
import {
  useState,
  useRef,
} from 'react'
import $ from 'jquery'
import {
  useAppDispatch,
  useAppSelector
} from './app/hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'

import {
  get_form_is_visible,
  update_form_is_visible,
} from './slice/postSlice'

import { custom_axios } from './axios'

export const Form = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const form_is_visible = useAppSelector(get_form_is_visible)

  const [type_state, set_type_state] = useState<string|undefined>('')
  const [show_image_url, set_show_image_url] = useState<boolean>(false)
  const [image_url, set_image_url] = useState<string | null>(null)
  const [has_image_src, set_has_image_src] = useState(false)

  const current_image_ref = useRef<HTMLImageElement>(null)
  const form_ref = useRef<HTMLFormElement>(null)

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_element = e.currentTarget
    const form_data = new FormData(form_element);
    const form_value = Object.fromEntries(form_data)

    if(!form_value?.['type']) {
      alert(t('Please select type'))
    } else {
      // if(post?.id) {
      //   form_data.set('id', post?.id.toString())
      // }

      custom_axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/create`,
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        if (response.data?.post) {
          form_element.reset()
          dispatch(update_form_is_visible(false))
          set_show_image_url(false)
          set_image_url(null)
          set_has_image_src(false)
          current_image_ref.current?.setAttribute('src', '')
        }
      })
    }
  }

  const image_input_changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value != "") {
      let reader = new FileReader();
      reader.onload = function () {
        if (current_image_ref?.current) {
          current_image_ref.current.setAttribute('src', this.result!.toString())
          current_image_ref.current.style.display = 'block'
          set_has_image_src(true)
        }
      }
      reader.readAsDataURL($<HTMLInputElement>(e.currentTarget)[0].files![0]);
    }
  }

  return (
    <div className={`fixed z-50 top-0 left-0 w-full h-full ${form_is_visible ? 'block' : 'hidden'} overflow-auto`}>
      <div
        className="absolute top-0 left-0 w-full h-full bg-black/90"
        onClick={ () => {
          dispatch(update_form_is_visible(false))
          form_ref?.current?.reset()
          set_show_image_url(false)
          set_image_url(null)
          set_has_image_src(false)
          current_image_ref.current?.setAttribute('src', '')
        } }></div>
      <div className='rounded w-90/100 bg-white absolute top-16 left-1/2 -translate-x-1/2 mb-8'>
        <div className="relative top-2 right-2 ml-auto w-fit">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className='cursor-pointer block w-12 h-12 bg-white rounded-full [&>path]:fill-black hover:[&>path]:fill-gray-600'
            onClick={ () => {
              dispatch(update_form_is_visible(false))
              form_ref?.current?.reset()
              set_show_image_url(false)
              set_image_url(null)
              set_has_image_src(false)
              current_image_ref.current?.setAttribute('src', '')
            } }
          />
        </div>
        <form
          className='px-8 pt-4 pb-7 text-black'
          method="post"
          onSubmit={ submit }
          ref={ form_ref }
        >
          <label>{ t('Title') }</label>
          <input
            required
            type='text'
            className='block bg-white px-2 py-1.5 text-black border-solid border border-black w-full mb-2'
            name='title'
            placeholder={ t('Title')! } />

          <label>{ t('URL') }</label>
          <input
            required
            type='text'
            className='block bg-white px-2 py-1.5 text-black border-solid border border-black w-full mb-2'
            name='url'
            placeholder={ t('URL')! } />

          <label>{ t('Tags') }</label>
          <input
            required
            type='text'
            className='block bg-white px-2 py-1.5 text-black border-solid border border-black w-full mb-3'
            name='tags'
            placeholder={ t('tag1, tag2, tag3 ...')! } />

          <label>{ t('Type') }</label>
          <div className='flex flex-row items-center flex-wrap mt-1 mb-3 gap-x-2 gap-y-2'>
            <div className='flex flex-row items-center gap-x-1'>
              <input
                type='checkbox'
                value='video'
                checked={ type_state === 'video' }
                onChange={ (e) => { set_type_state(e.target.value) } }
                className='block w-8 h-8 cursor-pointer border-solid border border-black'
                name='type' />
              <div>{ t('Video') }</div>
            </div>

            <div className='flex flex-row items-center gap-x-1'>
              <input
                type='checkbox'
                value='comic'
                checked={ type_state === 'comic' }
                onChange={ (e) => { set_type_state(e.target.value) } }
                className='block w-8 h-8 cursor-pointer border-solid border border-black'
                name='type' />
              <div>{ t('Comic') }</div>
            </div>

            <div className='flex flex-row items-center gap-x-1'>
              <input
                type='checkbox'
                value='photo'
                checked={ type_state === 'photo' }
                onChange={ (e) => { set_type_state(e.target.value) } }
                className='block w-8 h-8 cursor-pointer border-solid border border-black'
                name='type' />
              <div>{ t('Photo') }</div>
            </div>

            <div className='flex flex-row items-center gap-x-1'>
              <input
                type='checkbox'
                value='game'
                checked={ type_state === 'game' }
                onChange={ (e) => { set_type_state(e.target.value) } }
                className='block w-8 h-8 cursor-pointer border-solid border border-black'
                name='type' />
              <div>{ t('Game') }</div>
            </div>

            <div className='flex flex-row items-center gap-x-1'>
              <input
                type='checkbox'
                value='sound'
                checked={ type_state === 'sound' }
                onChange={ (e) => { set_type_state(e.target.value) } }
                className='block w-8 h-8 cursor-pointer border-solid border border-black'
                name='type' />
              <div>{ t('Sound') }</div>
            </div>
          </div>

          <div className='flex flex-row flex-wrap mb-2 justify-start gap-x-2'>
            <div className='w-full'>
              <div className='flex flex-row items-center gap-x-2'>
                <label className='block'>{ t('Image') }</label>
                <div>( { t('Show image url') }</div>
                <input
                  type='checkbox'
                  className='block w-6 h-6 cursor-pointer'
                  onChange={(e) => { set_show_image_url(e.target.checked) }}
                />
                <div>)</div>
              </div>
              <input
                type='hidden'
                name='show_image_url'
                value={show_image_url ? 1 : 0}
              />
              <div className={`${show_image_url ? 'hidden' : ''}`}>
                <input
                  type='file'
                  accept='image/*'
                  className='block bg-white px-2 py-1.5 text-black w-fit max-w-full'
                  name='image'
                  onChange={ image_input_changed }
                />
                <img
                  src=''
                  className={`max-w-full lg:max-w-[50%] mt-2 border border-black border-solid ${ has_image_src ? 'block' : 'hidden'}`}
                  ref={ current_image_ref }
                />
              </div>
              <div className={`${show_image_url ? '' : 'hidden'}`}>
                <input
                  type='text'
                  className='block mt-2 bg-white px-2 py-1.5 text-black w-full border-solid border border-black'
                  name='image_url'
                  onChange={ (e) => { set_image_url(e.target.value) } }
                />
                <img
                  src={ image_url ?? '' }
                  className={`max-w-full lg:max-w-[50%] mt-2 border border-black border-solid ${image_url ? 'block' : 'hidden'}`}
                />
              </div>
            </div>
          </div>

          <input
            type='submit'
            className='block mt-4 px-2.5 py-1.5 border border-black rounded shadow shadow-black text-white bg-rose-800 hover:bg-rose-900 cursor-pointer mx-auto w-fit active:shadow-none active:translate-y-px'
            value={ t('Submit')! } />
        </form>
      </div>
    </div>
  )
};
