import React from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
  useNavigate,
} from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { useGoogleLogin } from '@react-oauth/google'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGoogle,
} from '@fortawesome/free-brands-svg-icons'

// redux
import { useAppDispatch } from '../app/hooks'

// components
import { Normal as ButtonNormal } from '../storybook/Button/Normal/Normal'

// user slice
import {
  updateUserAsync,
  update_with_social_account,
} from '../slice/userSlice'

export function Login () {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  // redux
  const dispatch = useAppDispatch()

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form_data = new FormData(e.currentTarget)

    type ResponseType = {
      data: {
        refresh: string,
        access: string,
      }
    }

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/api/token/`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: ResponseType) => {
      dispatch(updateUserAsync(JSON.stringify(response.data)))

      const url_params = new URLSearchParams(window.location.search)
      const next = url_params.get('next')

      setTimeout(() => {
        if (next) {
          window.location.href = next
        } else {
          window.location.href = `/${i18n.language}`
        }
      }, 550)
    })
    .catch(() => {
      alert( t('ID or password is incorrect') )
    })
  }

  const google_login = useGoogleLogin({
    onSuccess: async (response) => {
      const update_with_social_account_respnose = await dispatch(update_with_social_account({
        'social_account_provider': 'google',
        'social_account_access_token': response['access_token'],
      }))

      if (!update_with_social_account_respnose.payload) {
        alert('There is no user associated with this account')
      } else {
        const url_params = new URLSearchParams(window.location.search)
        const next = url_params.get('next')

        setTimeout(() => {
          if (next) {
            window.location.href = next
          } else {
            window.location.href = `/${i18n.language}`
          }
        }, 550)
      }
    },
    onError: (error) => {
      console.log('Login Failed:', error)
    },
  })

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Cross-Origin-Opener-Policy"
          content="allow-popups"
        />
      </Helmet>

      <section className='p-2.5 lg:p-5'>
        <form
          onSubmit={ submit }
          method="post"
          className="text-black"
        >
          <label className="text-sky-900 dark:text-sky-300 block text-center mt-5 mb-2">ID</label>
          <input
            type='text'
            required
            className="block bg-white text-center p-2 border border-black border-solid w-full"
            name='id' />

          <label className="text-sky-900 dark:text-sky-300 block text-center mt-5 mb-2">{ t('Password') }</label>
          <input
            type='password'
            required
            className="block bg-white text-center p-2 border border-black border-solid w-full"
            name='password' />

          <ButtonNormal
            type='submit'
            content={ t('Submit') }
            classes='mx-auto mt-7'
          />
        </form>

        <div className='mt-5 flex flex-row flex-wrap gap-y-2 gap-x-2 items-center justify-center'>
          <button
            onClick={() => google_login()}
            className='px-3 py-2 rounded bg-white hover:bg-slate-700 hover:text-white cursor-pointer border border-solid border-black flex flex-row items-center gap-x-2'
          >
            <FontAwesomeIcon
              icon={faGoogle}
              className='block w-8 h-8' />
            <div>{ t('Sign in with Google') }</div>
          </button>
        </div>

        <button
          onClick={ () => { navigate(`/${i18n.language}/reset_password`) } }
          className="block cursor-pointer text-rose-900 dark:text-rose-300 mx-auto mt-6 text-ceter w-fit border-0 border-b border-dashed border-rose-900 dark:border-rose-300 px-2 pb-1 hover:text-rose-950 dark:hover:text-rose-500 hover:border-b-rose-950 dark:hover:border-b-rose-500"
        >
          { t('Reset password') }
        </button>

        <button
          onClick={ () => { navigate(`/${i18n.language}/sign_up`) } }
          className="block cursor-pointer text-rose-900 dark:text-rose-300 mx-auto mt-4 text-ceter w-fit border-0 border-b border-dashed border-rose-900 dark:border-rose-300 px-2 pb-1 hover:text-rose-950 dark:hover:text-rose-500 hover:border-b-rose-950 dark:hover:border-b-rose-500"
        >
          { t('Sign up') }
        </button>
      </section>
    </>
  )
}
