import { useTranslation } from 'react-i18next'
import {
  Routes,
  Route,
} from 'react-router-dom'

import { Search } from '../features/Search'
import { Home } from '../features/Home'
import { Good } from '../features/Good'
import { Profile } from '../features/Profile'

import { Setting } from '../features/Setting'
import { Username } from '../features/Setting/Username'
import { Icon } from '../features/Setting/Icon'
import { Description } from '../features/Setting/Description'
import { Password } from '../features/Setting/Password'
import { Twitter } from '../features/Setting/Twitter'
import { Instagram } from '../features/Setting/Instagram'
import { Facebook } from '../features/Setting/Facebook'
import { Line } from '../features/Setting/Line'

import { Login } from '../features/Login'

import { Index as ResetPasswordIndex } from '../features/ResetPassword/Index'
import { Authentication as ResetPasswordAuthentication } from '../features/ResetPassword/Authentication'
import { NewPassword as ResetPasswordNewPassword } from '../features/ResetPassword/NewPassword'

import { Index as SignUpIndex } from '../features/SignUp/Index'

import { Tos } from '../features/TermsOfService'
import { PrivacyPolicy } from '../features/PrivacyPolicy'

export const Main = () => {
  const { t, i18n } = useTranslation()

  return (
    <main className='relative bg-darkgray rounded opacity-0 duration-100 w-full flex-grow text-white'>
      <Routes>
        <Route path='/:locale' element={<Search />} />
        <Route path='/:locale/home/:user_id' element={<Home />} />
        <Route path='/:locale/good' element={<Good />} />
        <Route path='/:locale/profile/:user_id' element={<Profile />} />

        <Route path='/:locale/setting' element={<Setting />} />
        <Route path='/:locale/setting/username' element={<Username />} />
        <Route path='/:locale/setting/icon' element={<Icon />} />
        <Route path='/:locale/setting/description' element={<Description />} />
        <Route path='/:locale/setting/password' element={<Password />} />
        <Route path='/:locale/setting/twitter' element={<Twitter />} />
        <Route path='/:locale/setting/instagram' element={<Instagram />} />
        <Route path='/:locale/setting/facebook' element={<Facebook />} />
        <Route path='/:locale/setting/line' element={<Line />} />

        <Route path='/:locale/login' element={<Login />} />

        <Route path='/:locale/reset_password' element={<ResetPasswordIndex />} />
        <Route path='/:locale/reset_password/authentication' element={<ResetPasswordAuthentication />} />
        <Route path='/:locale/reset_password/new_password' element={<ResetPasswordNewPassword />} />

        <Route path='/:locale/sign_up' element={ <SignUpIndex /> } />

        <Route path='/:locale/tos' element={<Tos />} />
        <Route path='/:locale/privacy_policy' element={<PrivacyPolicy />} />
      </Routes>
    </main>
  )
}