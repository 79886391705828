import {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

import { custom_axios } from '../axios'

import {
  Post,
  PostType,
} from '../storybook/Post/Post'

export const Good = () => {
  const { t, i18n } = useTranslation()

  const [loading, set_loading] = useState<boolean>(false)

  const [posts, set_posts] = useState<PostType[]>([])
  const [has_more, set_has_more] = useState<boolean>(false)
  const [keyword, set_keyword] = useState<string>('')

  const load_more_point_ref = useRef<HTMLDivElement>(null)

  const fetch_posts = (is_updating: boolean, special_keyword?: string) => {
    custom_axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/goods`, {
        last_id: is_updating && posts.length ? posts[posts.length - 1]?.id : null,
        keyword: is_updating ? null : special_keyword||keyword,
      })
      .then((response: any) => {
        if (response.data?.posts) {
          set_has_more(response.data?.has_more)

          if (is_updating) {
            const new_posts = [...posts]
            set_posts(new_posts.concat(response.data?.posts))
          } else {
            set_posts(response.data?.posts)
          }

          set_loading(false)
        }
      })
  }

  useEffect(() => {
    const url_params = new URLSearchParams(window.location.search)
    const tags = url_params.getAll('tag').join(', ')

    set_keyword(tags)

    // When I get "keyword" variable in fetch_post function, it always return blank even if I updated it with above code. So I added "special_keyword" variable to fetch_post to pass the tag value of url params.
    fetch_posts(false, tags)
  }, [])

  const search = () => {
    fetch_posts(false)
    const tags = keyword.split(', ')
    const new_url = keyword != '' ? `${window.location.pathname}?tag=${tags.join('&tag=')}` : window.location.pathname
    history.pushState({}, "", new_url)
  }

  useEffect(() => {
    const scroll_event = () => {
      if (!loading && load_more_point_ref.current?.offsetTop) {
        if (window.scrollY + ($(window).outerHeight()||0) > load_more_point_ref.current?.offsetTop) {
          if (has_more) {
            console.log('load more posts')
            fetch_posts(true)
            set_loading(true)
          }
          window.removeEventListener('scroll', scroll_event)
        }
      }
    }
    window.addEventListener('scroll', scroll_event)
    return () => {
      window.removeEventListener('scroll', scroll_event)
    };
  }, [posts])

  const List = useCallback(() => posts.map((post, index) => {
    const tag_onclick = (tag: string) => {
      set_keyword(tag)
      const new_url = `${window.location.pathname}?tag=${tag}`
      history.pushState({}, "", new_url)
      fetch_posts(false, tag)
    }

    const update_record = (post: PostType) => {
      const new_posts = [...posts]
      new_posts[index] = post
      set_posts(new_posts)
    }

    const delete_record = () => {
      const new_posts = [...posts]
      new_posts.splice(index, 1)
      set_posts(new_posts)
    }

    return (
      <div
        key={ index }
        ref={index === posts.length - 10 ? load_more_point_ref : null}
      >
        <Post
          post={ post }
          tag_onclick={ tag_onclick }
          update_record={ update_record }
          delete_record={ delete_record }
        />
      </div>
    )
  }), [posts])

  return (
    <section className='p-2.5 lg:p-5'>
      <input
        type='text'
        placeholder={ t('tag1, tag2, tag3 ...')! }
        onInput={ (e) => { set_keyword(e.currentTarget.value) } }
        value={ keyword }
        className='block bg-white text-center text-black p-2 border border-black w-full' />

      <input
        type='button'
        className='block bg-rose-600 border-black border rounded px-6 py-3 cursor-pointer text-white shadow-black shadow hover:bg-rose-700 active:translate-y-0.5 active:shadow-none mx-auto mt-5'
        value={ t('Search')! }
        onClick={ search } />

      <div className='mb-16'>
        <div className="w-full grid gap-x-3 gap-y-6 lg:grid-cols-3 2xl:grid-cols-4 mt-5">
          <List />
        </div>

        <div
          className={`${loading ? 'display' : 'hidden'} mt-10`}>
          <FontAwesomeIcon
            icon={ faSpinner }
            className='fa-spin block w-14 h-14 mx-auto' />
        </div>
      </div>
    </section>
  )
}