import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

interface HeaderType {
  set_aside_mobile_is_open: Function,
}

export const Header = (props: HeaderType) => {
  const { t } = useTranslation()

  const set_aside_mobile_is_open = props.set_aside_mobile_is_open

  return (
    <header className='flex justify-between items-center min-h-14 py-2 px-1.5 text-white bg-darkgray rounded opacity-0 duration-100 w-full'>
      <h1 className='w-full ml-4 lg:ml-2 mr-2 lg:text-center'>{ t('Ero share') }</h1>

      <button
        onClick={ () => { set_aside_mobile_is_open(true) } }
        className="lg:hidden">
        <FontAwesomeIcon
          icon={ faBars }
          className="fa-solid text-white hover:green-yellow-100 fa-2x mr-2.5 cursor-pointer" />
      </button>
    </header >
  )
}