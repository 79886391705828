import React, {
  useEffect,
  useState,
} from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
  useLocation,
} from 'react-router-dom'

export const Authentication = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!location.state?.email) {
      navigate(
        `/${i18n.language}/reset_password`,
        { state: {} }
      )
    }
  }, [])

  const [missed_count, set_missed_count] = useState(0)

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);
    const form_value = Object.fromEntries(form_data)

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/reset_password/authentication`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response.data.successed) {
        navigate(
          `/${i18n.language}/reset_password/new_password`,
          { state: {
            email: location.state.email,
            code: form_value['code'],
          } }
        )
      } else {
        if (missed_count > 2) {
          alert(t('You missed too many times.'))
          navigate(
            `/${i18n.language}/reset_password`,
            { state: {} }
          )
        } else {
          set_missed_count(missed_count + 1)
        }
      }
    })
    .catch((response) => {
    })
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <h3 className={`mb-4 text-rose-300 text-center ${missed_count > 0 ? 'block' : 'hidden'}`}>
        { t('Miss count') }: { missed_count }
      </h3>

      <form
        onSubmit={ submit }
        method="post"
      >
        <input
          type='hidden'
          name='email'
          value={location.state?.email} />

        <h2
          className="text-pink-400 border-0 border-b border-dashed border-pink-400 text-center pb-1 mb-4">
          { t('Authentication code') }
        </h2>
        <input
          type='text'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='code' />

        <input
          type='submit'
          className="bg-rose-800 border-black border rounded block px-6 py-3 mx-auto mt-5 cursor-pointer text-white shadow-black shadow hover:bg-rose-600 active:translate-y-0.5 active:shadow-none"
          value={ t('Submit')! } />
      </form>

      <button
        onClick={ () => { navigate(`/${i18n.language}/login`) } }
        className="block cursor-pointer text-rose-400 mx-auto mt-6 text-ceter w-fit border-0 border-b border-dashed border-rose-400 px-2 pb-1 hover:text-rose-600 hover:border-b-rose-600">
        { t('Login') }
      </button>

      <button
        onClick={ () => { navigate(`/${i18n.language}/sign_up`) } }
        className="block cursor-pointer text-rose-400 mx-auto mt-4 text-ceter w-fit border-0 border-b border-dashed border-rose-400 px-2 pb-1 hover:text-rose-600 hover:border-b-rose-600">
        { t('Sign up') }
      </button>
    </section>
  )
}