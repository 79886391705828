import React, {
  useRef,
} from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";

export const Index = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const recaptcha_ref = useRef<any>(null)

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);
    const form_value = Object.fromEntries(form_data)
    const recaptcha_token = await recaptcha_ref.current.executeAsync()

    form_data.set('recaptcha_token', recaptcha_token)

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/reset_password`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      navigate(
        `/${i18n.language}/reset_password/authentication`,
        { state: {
          email: form_value['email']
        } }
      )
    })
    .catch((response) => {
    })
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <form
        onSubmit={ submit }
        method="post"
      >
        <ReCAPTCHA
          ref={recaptcha_ref}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        />

        <h2
          className="text-pink-400 border-0 border-b border-dashed border-pink-400 text-center pb-1 mb-4">
          { t('Email') }
        </h2>
        <input
          type='email'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='email' />

        <input
          type='submit'
          className="bg-rose-800 border-black border rounded block px-6 py-3 mx-auto mt-5 cursor-pointer text-white shadow-black shadow hover:bg-rose-600 active:translate-y-0.5 active:shadow-none"
          value={ t('Submit')! } />
      </form>

      <button
        onClick={ () => { navigate(`/${i18n.language}/login`) } }
        className="block cursor-pointer text-rose-400 mx-auto mt-6 text-ceter w-fit border-0 border-b border-dashed border-rose-400 px-2 pb-1 hover:text-rose-600 hover:border-b-rose-600">
        { t('Login') }
      </button>

      <button
        onClick={ () => { navigate(`/${i18n.language}/sign_up`) } }
        className="block cursor-pointer text-rose-400 mx-auto mt-4 text-ceter w-fit border-0 border-b border-dashed border-rose-400 px-2 pb-1 hover:text-rose-600 hover:border-b-rose-600">
        { t('Sign up') }
      </button>
    </section>
  )
}