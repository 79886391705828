import {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import {
  Link,
  useParams,
} from 'react-router-dom'

import {
  Post,
  PostType,
} from '../storybook/Post/Post'

import {
  Form,
} from '../storybook/Form/Form'

import {
  useAppSelector
} from '../app/hooks'

import {
  get_user,
  UserType
} from '../slice/userSlice'

import { custom_axios } from '../axios'

export const Home = () => {
  const { t, i18n } = useTranslation()
  const { user_id } = useParams()
  const login_user = useAppSelector(get_user)

  const [loading, set_loading] = useState<boolean>(false)
  const [form_showing, set_form_showing] = useState<boolean>(false)

  const [user, set_user] = useState<UserType>()
  const [posts, set_posts] = useState<PostType[]>([])
  const [has_more, set_has_more] = useState<boolean>(false)
  const [keyword, set_keyword] = useState<string>('')

  const load_more_point_ref = useRef<HTMLDivElement>(null)

  const fetch_posts = (is_updating: boolean, special_keyword?: string) => {
    custom_axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/home/${user_id}`, {
        last_id: is_updating && posts.length ? posts[posts.length - 1]?.id : null,
        keyword: is_updating ? null : special_keyword||keyword,
      })
      .then((response: any) => {
        if (response.data?.posts) {
          set_has_more(response.data?.has_more)

          if (is_updating) {
            const new_posts = [...posts]
            set_posts(new_posts.concat(response.data?.posts))
          } else {
            set_posts(response.data?.posts)
          }

          set_loading(false)

          if (response.data?.user) {
            set_user(response.data.user)
          }
        }
      })
  }

  useEffect(() => {
    const url_params = new URLSearchParams(window.location.search)
    const tags = url_params.getAll('tag').join(', ')

    set_keyword(tags)

    // When I get "keyword" variable in fetch_post function, it always return blank even if I updated it with above code. So I added "special_keyword" variable to fetch_post to pass the tag value of url params.
    fetch_posts(false, tags)
  }, [])

  const search = () => {
    fetch_posts(false)
    const tags = keyword.split(', ')
    const new_url = keyword != '' ? `${window.location.pathname}?tag=${tags.join('&tag=')}` : window.location.pathname
    history.pushState({}, "", new_url)
  }

  useEffect(() => {
    const scroll_event = () => {
      if (!loading && load_more_point_ref.current?.offsetTop) {
        if (window.scrollY + ($(window).outerHeight()||0) > load_more_point_ref.current?.offsetTop) {
          if (has_more) {
            console.log('load more posts')
            fetch_posts(true)
            set_loading(true)
          }
          window.removeEventListener('scroll', scroll_event)
        }
      }
    }
    window.addEventListener('scroll', scroll_event)
    return () => {
      window.removeEventListener('scroll', scroll_event)
    };
  }, [posts])

  const List = useCallback(() => posts.map((post, index) => {
    const tag_onclick = (tag: string) => {
      set_keyword(tag)
      const new_url = `${window.location.pathname}?tag=${tag}`
      history.pushState({}, "", new_url)
      fetch_posts(false, tag)
    }

    const update_record = (post: PostType) => {
      const new_posts = [...posts]
      new_posts[index] = post
      set_posts(new_posts)
    }

    const delete_record = () => {
      const new_posts = [...posts]
      new_posts.splice(index, 1)
      set_posts(new_posts)
    }

    return (
      <div
        key={ index }
        ref={index === posts.length - 10 ? load_more_point_ref : null}
      >
        <Post
          post={ post }
          tag_onclick={ tag_onclick }
          show_icon={false}
          update_record={ update_record }
          delete_record={ delete_record }
        />
      </div>
    )
  }), [posts])

  const FormBtn = () => {
    if (user_id === login_user?.id) {
      return (
        <div
          onClick={ () => { set_form_showing(true) } }
          className='w-fit underline text-emerald-300 hover:text-yellow-300 mb-1 cursor-pointer mt-2'>
          { t('Post') }
        </div>
      )
    }
  }

  const update_record = (post: PostType) => {
    const new_posts = [...posts]
    set_posts([post].concat(new_posts))
  }

  const Content = () => {
    if (!user) {
      return (
        <section className='p-2.5 lg:p-5'>
          <div className=''>
            { t('User does not exists, or there is no posts of this user.') }
          </div>
        </section>
      )
    } else {
      return (
        <>
          <div className='mt-5 mb-8'>
            <div className="flex flex-row items-center flex-wrap gap-x-2.5 justify-between">
              <div className="flex flex-row items-center gap-x-2.5">
                <img
                  className='rounded-full w-14 h-14 border-solid border border-white [&>path]:fill-white [&>path]:hover:fill-green-400'
                  src={ `${process.env.REACT_APP_BACKEND_HOST}${user.icon}` } />
                <div className=''>{ user.username }</div>
              </div>
              <Link
                to={`/${i18n.language}/profile/${user_id}`}
                className='block bg-sky-600 border-white border rounded px-5 py-2.5 cursor-pointer text-white shadow-black shadow hover:bg-sky-700 active:translate-y-0.5 active:shadow-none'>
                { t('Profile') }
              </Link>
            </div>
          </div>

          <input
            type='text'
            placeholder={ t('tag1, tag2, tag3 ...')! }
            onBlur={ (e) => { set_keyword(e.target.value) } }
            defaultValue={ keyword }
            className='block bg-white text-center text-black p-2 border border-black w-full' />

          <input
            type='button'
            className='block bg-rose-600 border-black border rounded px-6 py-3 cursor-pointer text-white shadow-black shadow hover:bg-rose-700 active:translate-y-0.5 active:shadow-none mx-auto mt-5'
            value={ t('Search')! }
            onClick={ search } />

          <div className='mb-16'>
            <div className="w-full grid gap-x-3 gap-y-6 lg:grid-cols-3 2xl:grid-cols-4 mt-5">
              <List />
            </div>

            <div
              className={`${loading ? 'display' : 'hidden'} mt-10`}>
              <FontAwesomeIcon
                icon={ faSpinner }
                className='fa-spin block w-14 h-14 mx-auto' />
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <FormBtn />

      <Form
        showing={ form_showing }
        set_showing={ set_form_showing }
        update_record={ update_record }
        user={ user }
        set_user={ set_user }
      />

      <Content />
    </section>
  )
}