import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import userReducer from '../slice/userSlice';
import postReducer from '../slice/postSlice';
import slideshowReducer from '../slice/slideshowSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    post: postReducer,
    slideshow: slideshowReducer,
  },
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
