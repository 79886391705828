import React, {
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha"

import { useGoogleLogin } from '@react-oauth/google'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGoogle,
} from '@fortawesome/free-brands-svg-icons'

// components
import Menu from './Menu'
import { Normal as ButtonNormal } from '../../storybook/Button/Normal/Normal'

interface Props {
  set_showing: (showing: string) => void,
  set_email: (email: string) => void,
  set_social_account_provider: (provider: string) => void,
  set_social_account_access_token: (token: string) => void,
}

export default function Authentication ({
  set_showing,
  set_email,
  set_social_account_provider,
  set_social_account_access_token,
}: Props) {
  const { t, i18n } = useTranslation()
  // refs
  const recaptcha_ref = useRef<any>(null)

  // states
  const [agreed_tos, set_agreed_tos] = useState(false)
  const [agreed_privacy_policy, set_agreed_privacy_policy] = useState(false)

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const form_data = new FormData(e.currentTarget)

    const form_value = Object.fromEntries(form_data)

    const recaptcha_token = await recaptcha_ref.current.executeAsync()

    form_data.set('recaptcha_token', recaptcha_token)

    type ResponseType = {
      data: {
        error_message?: string,
      }
    }

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/sign_up`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: ResponseType) => {
      if (response.data?.error_message) {
        alert(response.data?.error_message)
      } else {
        set_showing('validate')
        set_email(form_value['email'].toString())
      }
    })
    .catch(() => {})
  }

  const google_login = useGoogleLogin({
    onSuccess: async (response) => {
      set_showing('register')
      set_social_account_provider('google')
      set_social_account_access_token(response['access_token'])
    },
    onError: (error) => {
      console.log('Login Failed:', error)
    },
  })

  return (
    <section className='p-2.5 lg:p-5'>
      <form
        onSubmit={ submit }
        method="post"
      >
        <ReCAPTCHA
          ref={ recaptcha_ref }
          size="invisible"
          sitekey={ process.env.REACT_APP_RECAPTCHA_SITE_KEY! }
        />

        <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mb-4">
          { t('Email') }
        </h2>
        <input
          type='email'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='email' />

        <div className="flex flex-row items-center justify-center mb-3 mt-2">
          <div className="flex flex-row justify-center items-center mt-5 mr-4">
            <input
              type='checkbox'
              className="w-8 h-8 cursor-pointer block bg-white mr-2"
              checked={ agreed_privacy_policy }
              onChange={ (e) => { set_agreed_privacy_policy(e.target.checked) } }
            />

            <div className={`flex flex-row justify-center items-center ${ i18n.language === 'ja' ? 'flex-row-reverse' : '' }`}>
              <div className="block ml-1">{ t('Sign up agree') }</div>

              <a
                href={ `/privacy_policy` }
                target='_blank'
                className="block ml-1 text-rose-900 dark:text-rose-300 hover:text-rose-950 dark:hover:text-rose-500"
              >
                { t('Privacy policy') }
              </a>
            </div>
          </div>

          <div className="flex flex-row justify-center items-center mt-5">
            <input
              type='checkbox'
              className="w-8 h-8 cursor-pointer block bg-white mr-2"
              checked={ agreed_tos }
              onChange={ (e) => { set_agreed_tos(e.target.checked) } }
            />

            <div className={`flex flex-row justify-center items-center ${ i18n.language === 'ja' ? 'flex-row-reverse' : '' }`}>
              <div className="block ml-1">{ t('Sign up agree') }</div>

              <a
                href={ `/tos` }
                target='_blank'
                className="block ml-1 text-rose-900 dark:text-rose-300 hover:text-rose-950 dark:hover:text-rose-500"
              >
                { t('TOS') }
              </a>
            </div>
          </div>
        </div>

        <ButtonNormal
          type='submit'
          disabled={ !(agreed_tos && agreed_privacy_policy) }
          content={ t('Submit') }
          classes='mx-auto mt-7 disabled:bg-gray-400'
        />
      </form>

      <div className='mt-5 flex flex-row flex-wrap gap-y-2 gap-x-2 items-center justify-center'>
        <button
          onClick={() => google_login()}
          disabled={ !(agreed_tos && agreed_privacy_policy) }
          className='px-3 py-2 rounded bg-sky-100 disabled:bg-slate-100 enabled:hover:bg-slate-700 enabled:hover:text-white cursor-pointer border border-solid border-black flex flex-row items-center gap-x-2'
        >
          <FontAwesomeIcon
            icon={faGoogle}
            className='block w-8 h-8' />
          <div>{ t('Sign in with Google') }</div>
        </button>
      </div>

      <Menu />
    </section>
  )
}
