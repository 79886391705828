import { useNavigate } from 'react-router-dom'

interface ItemProps {
  name: string,
  path?: string,
  theme: 'red' | 'lime' | 'blue' | 'green' | 'violet',
  action?: 'navigate' | 'redirect_target_blank' | 'customize',
  onclick_action?: Function,
}

export const Item = ({
  name,
  path = '',
  theme,
  action = 'navigate',
  onclick_action,
}: ItemProps) => {
  const navigate = useNavigate()

  let color = ''
  switch (theme) {
    case 'red':
      color = 'text-rose-800 lg:text-rose-300 hover:bg-rose-800'
      break
    case 'lime':
      color = 'text-lime-800 lg:text-lime-300 hover:bg-lime-800'
      break
    case 'blue':
      color = 'text-blue-800 lg:text-sky-300 hover:bg-blue-800'
      break
    case 'green':
      color = 'text-green-800 lg:text-green-300 hover:bg-green-800 leading-tight'
      break
    case 'violet':
      color = 'text-violet-800 lg:text-violet-300 hover:bg-violet-800'
      break
  }

  const btn_style = `cursor-pointer p-1.5 rounded duration-500 hover:text-white ${color}`

  switch (action) {
    case 'navigate':
      return (
        <button
          onClick={ () => { navigate(path) } }
          className={ btn_style }>
          { name }
        </button>
      );
    case 'redirect_target_blank':
      return (
        <a
          href={ path }
          target='_blank'
          className={ btn_style }>
          { name }
        </a>
      );
    case 'customize':
      return (
        <button
          onClick={ () => { if (onclick_action) { onclick_action() } } }
          className={ btn_style }>{ name }</button>
      );
    default:
      return (
        <div className={ btn_style }>{ name }</div>
      );
  }
};
