import React, {
  useState,
} from 'react'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from '../../app/hooks'
import {
  useNavigate,
} from 'react-router-dom'

import {
  get_user,
  update_user,
} from '../../slice/userSlice'

import {
  custom_axios,
} from '../../axios'

import { CropImage } from '../../storybook/CropImage/CropImage'

export const Icon = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(get_user)

  const [icon_src, set_icon_src] = useState<string>()
  const [crop_is_open, set_crop_is_open] = useState(false)
  const [image_for_crop, set_image_for_crop] = useState('')

  const icon_default = `${process.env.REACT_APP_BACKEND_HOST}/en/media/icon/default.png`

  const dataURI_to_blob = (dataURI: string) => {
    let byteString;
    
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    let mineString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mineString });
  }

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);

    if (icon_src) {
      let image_for_store: HTMLImageElement = document.createElement('img');
      image_for_store.src = icon_src;

      form_data.set('icon', new File([dataURI_to_blob(icon_src)], "icon.png", { type: 'image/png' }))

      console.log('---')
      console.log(icon_src)

      custom_axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/setting/icon`,
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        if (response.data.user) {
          dispatch(update_user(response.data.user))
          navigate(`/${i18n.language}`)
        } else {
          alert(t('Error'))
        }
      })
      .catch((response) => {
        alert(t('Error'))
      })
    } else {
      return
    }
  }

  const icon_input_changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value != "") {
      let reader = new FileReader();
      reader.onload = function () {
        set_image_for_crop(this.result!.toString())
        set_crop_is_open(true)
      }
      reader.readAsDataURL($<HTMLInputElement>(e.currentTarget)[0].files![0]);
    }
  }

  if (user) {
    return (
      <section className='p-2.5 lg:p-5'>
        <form
          onSubmit={ submit }
          method="post">

          <h2 className="text-pink-400 border-0 border-b border-dashed border-pink-400 text-center pb-1 mb-4">
            { t('Icon') }
          </h2>

          <CropImage
            crop_is_open={ crop_is_open }
            set_crop_is_open={ set_crop_is_open }
            image_for_crop={ image_for_crop }
            set_src={set_icon_src}
            rate_width={1}
            rate_height={1} />

          <div className="relative w-11/12 lg:w-3/5 mx-auto rounded-full">
            <img
              src={ icon_src ? icon_src : (user?.icon ? `${process.env.REACT_APP_BACKEND_HOST}${user.icon}` : icon_default) }
              className="block w-full mx-auto border-2 border-white border-solid cursor-pointer rounded-full" />
            <input
              type='file'
              onChange={icon_input_changed}
              accept='image/*'
              className='block absolute top-0 left-0 opacity-0 w-full h-full rounded-full cursor-pointer' />
          </div>

          <input
            type='submit'
            className="bg-rose-600 border-black border rounded block px-6 py-3 mx-auto mt-7 cursor-pointer text-white shadow-black shadow hover:bg-rose-700 active:translate-y-0.5 active:shadow-none"
            value={ t('Submit')! } />
        </form>
      </section>
    )
  } else {
    return (
      <h2 className='text-center text-red-300 mt-4'>{ t('Please login first') }</h2>
    )
  }
}