import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from '../app/hooks'
import axios from 'axios'

import {
  get_user,
  clear_user,
} from '../slice/userSlice'

import { Item } from '../storybook/Aside/Item/Item';

import { Functions } from './Aside/Functions'
import { Languages } from './Aside/Languages'

export const Aside = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector(get_user)

  const UserProfile = () => {
    if (user) {
      return (
        <div className='flex flex-col justify-center items-center pb-2 pt-3 mb-2.5 bg-green-900'>
          <img
            src={ `${process.env.REACT_APP_BACKEND_HOST}${user.icon}` }
            className="block w-14 h-14 rounded-full border border-solid border-silver cursor-pointer" />
          <div className='w-11/12 text-left overflow-auto mt-1'>@ { user.id }</div>
        </div>
      )
    }
  }

  const Login = () => {
    if (!user) {
      return (
        <Item
          name={ t('Login') }
          path={`/${i18n.language}/login`}
          theme='lime' />
      )
    } else {
      const clicked = () => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/logout`,
          data: {
            'token': JSON.parse(localStorage.getItem('user')!)['refresh'],
          },
          headers: {},
        })
        .then((response) => {})
        .catch((response) => {})

        dispatch(clear_user())
      }

      return (
        <>
          <Item
            name={ t('Setting') }
            theme='lime'
            path={`/${i18n.language}/setting`}
          />
          <Item
            name={ t('Logout') }
            theme='lime'
            action='customize'
            onclick_action={ clicked }
          />
        </>
      )
    }
  }

  return (
    <aside
      className='bg-darkgreen text-white hidden lg:block opacity-0 duration-100 text-center pb-24 w-0 lg:w-12/100 rounded'>
      <div className="flex flex-col justify-start items-stretch">
        <UserProfile />
        <Functions />
        <Login />

        <div className='border-0 border-b border-solid border-black pb-1 mb-1 mt-2.5 text-center lg:border-b-white'>Langs</div>
        <Languages />

        <div className='border-0 border-b border-solid border-black pb-1 mb-1 mt-2.5 text-center lg:border-b-white'>{ t('Other sites') }</div>
        <Item
          name={ t('Twitter download ranking') }
          path={`https://x.hamary.net/${i18n.language}/video_download_ranking/1`}
          theme='green'
          action='redirect_target_blank' />

        <div className='border-0 border-b border-solid border-black pb-1 mb-1 mt-2.5 text-center lg:border-b-white'>{ t('Games') }</div>
        <Item
          name='Death Run'
          path='https://hamary.net/games/DeathRun'
          theme='violet'
          action='redirect_target_blank' />
      </div>
    </aside>
  )
}