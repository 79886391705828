import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from '../app/hooks'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'

import {
  get_user,
  clear_user,
} from '../slice/userSlice'

import { Item } from '../storybook/AsideMobile/Item/Item';

import { Functions } from './AsideMobile/Functions'
import { Languages } from './AsideMobile/Languages'

interface AsideMobileType {
  aside_mobile_is_open: boolean,
  set_aside_mobile_is_open: Function,
}

export const AsideMobile = (props: AsideMobileType) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector(get_user)

  const aside_mobile_is_open = props.aside_mobile_is_open
  const set_aside_mobile_is_open = props.set_aside_mobile_is_open

  const UserProfile = () => {
    if (user) {
      return (
        <div className='flex flex-col justify-center items-center'>
          <img
            src={ `${process.env.REACT_APP_BACKEND_HOST}${user.icon}` }
            className="block w-14 h-14 rounded-full border border-solid border-silver cursor-pointer" />
          <div className='text-left mt-1.5 text-black'>@ { user.id }</div>
        </div>
      )
    } else {
      return(<div></div>)
    }
  }

  const Login = () => {
    if (!user) {
      return (
        <Item
          name={ t('Login') }
          path={`/${i18n.language}/login`}
          theme='lime'
          set_aside_mobile_is_open={set_aside_mobile_is_open}
        />
      )
    } else {
      const clicked = () => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/logout`,
          data: {
            'token': JSON.parse(localStorage.getItem('user')!)['refresh'],
          },
          headers: {},
        })
        .then((response) => {})
        .catch((response) => {})

        dispatch(clear_user())
      }

      return (
        <>
          <Item
            name={ t('Setting') }
            theme='lime'
            path={`/${i18n.language}/setting`}
            set_aside_mobile_is_open={set_aside_mobile_is_open}
          />
          <Item
            name={ t('Logout') }
            theme='lime'
            action='customize'
            onclick_action={ clicked }
            set_aside_mobile_is_open={set_aside_mobile_is_open}
          />
        </>
      )
    }
  }

  return (
    <aside
      className={`${ aside_mobile_is_open ? 'translate-x-0' : 'translate-x-full' } text-white z-30 flex flex-col top-0 right-0 w-10/12 h-full fixed duration-100 bg-slate-200 rounded-tl rounded-bl text-center justify-start px-1.5 pb-2 pt-5 border-solid border border-blue-800`}>
      <div className='flex flex-row justify-between items-start mb-5'>
        <UserProfile />
        <FontAwesomeIcon
          icon={faCircleXmark}
          className='cursor-pointer block w-12 h-12 bg-white rounded-full [&>path]:fill-blue-800 hover:[&>path]:fill-blue-500'
          onClick={ () => { set_aside_mobile_is_open(false) } }
        />
      </div>
      <div className="flex flex-col justify-start items-stretch">
        <Functions
          set_aside_mobile_is_open={set_aside_mobile_is_open}
        />
        <Login />

        <div className='border-0 border-b border-solid border-black text-black pb-1 mb-1 mt-2.5 text-center lg:border-b-white'>Langs</div>
        <Languages
          set_aside_mobile_is_open={set_aside_mobile_is_open}
        />

        <div className='border-0 border-b border-solid border-black text-black pb-1 mb-1 mt-2.5 text-center lg:border-b-white'>{ t('Other sites') }</div>
        <Item
          name={ t('Twitter download ranking') }
          path={`https://x.hamary.net/${i18n.language}/video_download_ranking/1`}
          theme='green'
          action='redirect_target_blank'
          set_aside_mobile_is_open={set_aside_mobile_is_open}
        />

        {/* <div className='border-0 border-b border-solid border-black text-black pb-1 mb-1 mt-2.5 text-center lg:border-b-white'>{ t('Games') }</div> */}
      </div>
    </aside>
  )
}