import {
  useEffect,
  useState,
} from 'react'
import $ from 'jquery'
import {
  useAppDispatch,
  useAppSelector
} from './app/hooks'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import {
  useNavigate,
} from 'react-router-dom'

import {
  Form,
} from './Form'

import {
  update_form_is_visible,
} from './slice/postSlice'

import {
  get_user,
  updateUserAsync,
} from './slice/userSlice'
import { updateVisible } from './slice/slideshowSlice'

import Slideshow from '@hamary/slideshow'
// import Slideshow from '../../../react-slideshow/src/main'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faHouse,
  faThumbsUp,
  faMagnifyingGlass,
  faXmark,
  faChevronDown,
  faPenNib,
} from '@fortawesome/free-solid-svg-icons'

import { Main } from './layout/Main'
import { Header } from './layout/Header'
import { Footer } from './layout/Footer'
import { Aside } from './layout/Aside'
import { AsideMobile } from './layout/AsideMobile'

export const Body = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(get_user)
  const slideshowImages = useAppSelector((state) => state.slideshow.images)
  const slideshowVisible = useAppSelector((state) => state.slideshow.visible)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const [aside_mobile_is_open, set_aside_mobile_is_open] = useState<boolean>(false)
  const [menu_icon_is_open, set_menu_icon_is_open] = useState<boolean>(false)

  useEffect(() => {
    try {
      if (!user && localStorage.getItem("user")) {
        dispatch(updateUserAsync(localStorage.getItem("user")!))
      }
    } catch {
    }

    setTimeout(function () { $('header').css('opacity', '1'); }, 200);
    setTimeout(function () { $('aside').css('opacity', '1'); }, 400);
    setTimeout(function () { $('main').css('opacity', '1'); }, 600);
    setTimeout(function () { $('footer').css('opacity', '1'); }, 800);
    setTimeout(function () { 
      $('main').css(
        'min-height',
        (($(window).innerHeight() || 0) - ($('header').outerHeight() || 0) - ($('footer').outerHeight() || 0) - 4) + 'px'
      );
    }, 200);

    if (!/^\/(en|ja|zh-hant|zh-hans)+/.test(window.location.pathname)) {
      navigate(window.location.pathname.replace(window.location.pathname, `/en${window.location.pathname}`))
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{ t('Ero share') }</title>
        <meta name="description" content={ t('EroShare.description')! } />
      </Helmet>

      <Slideshow
        images={slideshowImages}
        showing={slideshowVisible}
        z_index={100}
        on_close={() => {
          dispatch(updateVisible(false))
        }}
      />

      {user != null && 
        <Form/>
      }

      <div className='flex flex-row justify-center m-px gap-x-px'>
        <Aside />
        <AsideMobile
            aside_mobile_is_open={ aside_mobile_is_open }
            set_aside_mobile_is_open={ set_aside_mobile_is_open }
        />

        <div className='flex flex-col w-full lg:w-88/100 flex-grow gap-y-px'>
          <Header
            set_aside_mobile_is_open={ set_aside_mobile_is_open }
          />
          <Main />
          <Footer />
        </div>
        <a href="#root">
          <div className='fixed bottom-2 left-2 z-30 w-16 h-16 flex items-center justify-center border-solid border-2 border-black rounded-full bg-gray-100 hover:bg-gray-300 cursor-pointer'> 
            <FontAwesomeIcon
              icon={faArrowUp}
              className='cursor-pointer block w-10 h-10 [&>path]:fill-black'
            />
          </div>
        </a>

        <div className='fixed bottom-2 right-2 z-30'>
          <div className={`${menu_icon_is_open ? 'block' : 'hidden'}`}>
            <button
              onClick={() => {
                navigate(
                  user
                  ? `/${i18n.language}/home/${user?.id}`
                  : `/${i18n.language}/login`
                )
                set_menu_icon_is_open(false)
              }}
              className="cursor-pointer w-16 h-16 flex items-center justify-center border-solid border-2 border-black rounded-full bg-gray-100 hover:bg-gray-300 mb-1">
              <FontAwesomeIcon
                icon={faHouse}
                className='cursor-pointer block w-10 h-10 [&>path]:fill-black'
              />
            </button>

            <button
              onClick={() => {
                user
                ? dispatch(update_form_is_visible(true))
                : navigate(`/${i18n.language}/login`)
              }}
              className="cursor-pointer w-16 h-16 flex items-center justify-center border-solid border-2 border-black rounded-full bg-gray-100 hover:bg-gray-300">
              <FontAwesomeIcon
                icon={faPenNib}
                className='cursor-pointer block w-10 h-10 [&>path]:fill-black'
              />
            </button>

            <button
              onClick={() => {
                navigate(
                  user
                  ? `/${i18n.language}/good`
                  : `/${i18n.language}/login?next=/${i18n.language}/good`
                )
                set_menu_icon_is_open(false)
              }}
              className="cursor-pointer w-16 h-16 flex items-center justify-center border-solid border-2 border-black rounded-full bg-gray-100 hover:bg-gray-300 mb-1">
              <FontAwesomeIcon
                icon={faThumbsUp}
                className='cursor-pointer block w-10 h-10 [&>path]:fill-black'
              />
            </button>

            <button
              onClick={() => {
                navigate(`/${i18n.language}`)
                set_menu_icon_is_open(false)
              }}
              className="cursor-pointer w-16 h-16 flex items-center justify-center border-solid border-2 border-black rounded-full bg-gray-100 hover:bg-gray-300 mb-1">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className='cursor-pointer block w-10 h-10 [&>path]:fill-black'
              />
            </button>

            <div
              className='cursor-pointer w-16 h-16 flex items-center justify-center border-solid border-2 border-black rounded-full bg-gray-100 hover:bg-gray-300'>
              <FontAwesomeIcon
                icon={faXmark}
                onClick={ () => { set_menu_icon_is_open(false) } }
                className='cursor-pointer block w-10 h-10 [&>path]:fill-black'
              />
            </div>
          </div>
          <div
            className={`cursor-pointer w-16 h-16 flex items-center justify-center border-solid border-2 border-black rounded-full bg-gray-100 hover:bg-gray-300 ${menu_icon_is_open ? 'hidden' : 'block'}`}>
            <FontAwesomeIcon
              icon={faChevronDown}
              onClick={ () => { set_menu_icon_is_open(true) } }
              className='cursor-pointer block w-10 h-10 [&>path]:fill-black' />
          </div>
        </div>
      </div>
    </>
  )
}