import { useTranslation } from 'react-i18next'
import {
  useAppSelector
} from '../../app/hooks'
import {
  Link,
  useNavigate,
} from 'react-router-dom'
import { custom_axios } from '../../axios'

import {
  get_user,
} from '../../slice/userSlice'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGear,
  faThumbsUp,
  faThumbsDown,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import { PostType } from '../Post/Post'

interface PostMenuProps {
  type: string,
  post: PostType,
  good_count: number,
  bad_count: number,
  show_icon: boolean,
  update_record: Function,
  delete_record: Function,
  form_showing: boolean,
  set_form_showing: Function,
}

export const PostMenu = ({
  type,
  post,
  good_count,
  bad_count,
  show_icon,
  update_record,
  delete_record,
  form_showing,
  set_form_showing,
}: PostMenuProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const user = useAppSelector(get_user)

  const Gear = () => {
    if (user?.id === post.user.id) {
      return (
        <>
          <FontAwesomeIcon
            icon={ faTrash }
            onClick={ () => { 
              let confirmed = window.confirm(t('Are you sure to delete this post?')!)
              if (confirmed) {
                custom_axios
                  .get(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/delete/${post.id}`, {})
                  .then((response: any) => {
                    delete_record()
                  })
              }
            } }
            className='block w-10 h-10 cursor-pointer text-black hover:text-slate-700' />
          <FontAwesomeIcon
            icon={ faGear }
            onClick={ () => { set_form_showing(true) } }
            className='block w-10 h-10 cursor-pointer text-black hover:text-violet-900' />
        </>
      )
    }
  }

  const good_onclick = () => {
    if (user) {
      custom_axios
        .get(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/good/${post.id}`, {})
        .then((response: any) => {
          if (response.data?.post) {
            update_record(response.data.post)
          }
        })
    } else {
      navigate(`/${i18n.language}/login`)
    } 
  }

  const bad_onclick = () => {
    if (user) {
      custom_axios
        .get(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/bad/${post.id}`, {})
        .then((response: any) => {
          if (response.data?.post) {
            update_record(response.data.post)
          }
        })
    } else {
      navigate(`/${i18n.language}/login`)
    } 
  }

  return (
    <>
      <div>
        { t('TYPE') }: { t(type) }
      </div>
      <div
        className={`${show_icon ? 'block' : 'hidden'}`}
      >
        { post.user.username }
      </div>
      <div className="flex flex-row items-center flex-wrap gap-x-2">
        <Link
          className={`${show_icon ? 'block' : 'hidden'} flex items-center`}
          to={`/${i18n.language}/home/${post.user.id}`}>
          <img
            className='rounded-full cursor-pointer w-12 h-12 border-solid border border-black'
            src={ `${process.env.REACT_APP_BACKEND_HOST}${post.user.icon}` } />
        </Link>

        <div className='flex flex-row items-center gap-x-1'>
          <button
            onClick={ good_onclick }
            className='block'>
            <FontAwesomeIcon
              icon={ faThumbsUp }
              className={`block w-10 h-10 cursor-pointer ${post.is_pressing_good ? 'text-rose-900' : 'text-black'} hover:text-rose-500`} />
          </button>
          <p className="">{ good_count }</p>
        </div>

        <div className='flex flex-row items-center gap-x-1'>
          <button
            onClick={ bad_onclick }
            className='block'>
            <FontAwesomeIcon
              icon={ faThumbsDown }
              className={`block w-10 h-10 cursor-pointer ${post.is_pressing_bad ? 'text-indigo-900' : 'text-black'} hover:text-indigo-500`} />
          </button>
          <p className="">{ bad_count }</p>
        </div>

        <Gear />
      </div>
    </>
  )
};
