import { useTranslation } from 'react-i18next'

import $ from 'jquery'

import {
  useNavigate,
} from 'react-router-dom'
import {
  useRef,
} from 'react'

interface CropImageProps {
  crop_is_open: boolean,
  set_crop_is_open: Function,
  image_for_crop: string,
  set_src: Function,
  rate_width: number,
  rate_height: number,
}

export const CropImage = ({
  crop_is_open,
  set_crop_is_open,
  image_for_crop,
  set_src,
  rate_width,
  rate_height,
}: CropImageProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  let image = new Image();

  const canvas_ref = useRef(null)
  const range_ref = useRef(null)
  let imgCropCanvas: HTMLCanvasElement = document.querySelector('canvas.image-crop')!

  let x: number = 0.0;
  let y: number = 0.0;
  let v: number = 1.0;
  let mdx: number = 0.0;
  let mdy: number = 0.0;

  let iw = 0;
  let ih = 0;
  let cw = 0;
  let ch = 0;
  let cropWidth = 0;
  let cropHeight = 0;
  let cropX = 0;
  let maxCropWidth = 0;
  let maxCropHeight = 0;

  image.onload = function () {
    imgCropCanvas = canvas_ref.current!;
    imgCropCanvas.setAttribute('width', window.innerWidth * 0.97 + 'px');
    let range: any = range_ref.current;

    iw = image.width;
    ih = image.height;
    cw = imgCropCanvas.width;
    ch = ih * cw / iw;
    cropWidth = cw;
    cropHeight = (rate_height * cropWidth) / rate_width;
    cropX = 0;
    maxCropWidth = cw;
    maxCropHeight = window.innerHeight - 340;

    if (maxCropHeight < cropHeight) {
      cropWidth = cropWidth * maxCropHeight / cropHeight;
      cropHeight = maxCropHeight;
    }
    if (maxCropWidth < cropWidth) {
      cropHeight = cropHeight * maxCropWidth / cropWidth;
      cropWidth = maxCropWidth;
    }
    if (cropHeight > ch) {
      cropWidth = cropWidth * ch / cropHeight;
      cropHeight = ch;
    }
    if (cropWidth > cw) {
      cropHeight = cropHeight * cw / cropWidth;
      cropWidth = cw;
    }
    if (cw > cropWidth) {
      cropX = (cw - cropWidth) / 2;
    }

    let isMouseDown: boolean = false;

    imgCropCanvas.setAttribute("height", ch + "px");
    $(range).val(1)
    function drawCanvas() {
      const ctx = imgCropCanvas.getContext('2d')!;
      ctx.fillStyle = 'rgb(200,200,200)';
      ctx.fillRect(0, 0, cw, ch);
      ctx.fillStyle = 'rgba(150, 150, 150, 0.7)';
      ctx.drawImage(image, x, y, cw * v, ch * v);
      if (cropX > 0) {
        ctx.fillRect(0, 0, cropX, ch);
      }
      if (cropX + cropWidth < cw) {
        ctx.fillRect(cropX + cropWidth, 0, (cw - cropX - cropWidth), ch);
      }
      if (cropHeight < ch) {
        ctx.fillRect(0, cropHeight, cw, (ch - cropHeight));
      }
    }
    drawCanvas();

    imgCropCanvas.removeEventListener("mousedown", mousedown);
    imgCropCanvas.addEventListener('mousedown', mousedown);
    function mousedown(e: MouseEvent) {
      isMouseDown = true;
      mdx = e.pageX - x;
      mdy = e.pageY - y;
      return false;
    }

    imgCropCanvas.removeEventListener("touchstart", touchstart);
    imgCropCanvas.addEventListener('touchstart', touchstart);
    function touchstart(e: TouchEvent) {
      e.preventDefault();
      isMouseDown = true;
      mdx = e.changedTouches[0].pageX - x;
      mdy = e.changedTouches[0].pageY - y;
      return false;
    }

    imgCropCanvas.removeEventListener("touchend", mouseup);
    imgCropCanvas.addEventListener('touchend', mouseup);
    imgCropCanvas.removeEventListener("mouseup", mouseup);
    imgCropCanvas.addEventListener('mouseup', mouseup);
    imgCropCanvas.removeEventListener("mouseout", mouseup);
    imgCropCanvas.addEventListener('mouseout', mouseup);
    function mouseup() {
      if (isMouseDown == false) return;
      isMouseDown = false;
      return false
    }

    imgCropCanvas.removeEventListener("mousemove", mousemove);
    imgCropCanvas.addEventListener('mousemove', mousemove);
    function mousemove(e: MouseEvent) {
      if (isMouseDown == false) return false;
      if (e.pageY - mdy > 0) {
        y = 0;
      } else {
        if (ch * v - cropHeight + e.pageY - mdy <= 0) {
          y = cropHeight - ch * v;
        } else {
          y = (e.pageY - mdy);
        }
      }

      if ((cw - cropWidth) / 2 - (e.pageX - mdx) <= 0) {
        x = (cw - cropWidth) / 2;
      } else {
        if ((cw - cropWidth) / 2 + e.pageX - mdx + (cw * v - cw) <= 0) {
          x = 0 - (cw - cropWidth) / 2 - (cw * v - cw)
        } else {
          x = (e.pageX - mdx);
        }
      }
      drawCanvas();
      return false
    }

    imgCropCanvas.removeEventListener("touchmove", touchmove);
    imgCropCanvas.addEventListener('touchmove', touchmove);
    function touchmove(e: TouchEvent) {
      e.preventDefault();
      if (isMouseDown == false) return;
      if (e.changedTouches[0].pageY - mdy > 0) {
        y = 0;
      } else {
        if (ch * v - cropHeight + e.changedTouches[0].pageY - mdy <= 0) {
          y = cropHeight - ch * v;
        } else {
          y = (e.changedTouches[0].pageY - mdy);
        }
      }

      if ((cw - cropWidth) / 2 - (e.changedTouches[0].pageX - mdx) <= 0) {
        x = (cw - cropWidth) / 2;
      } else {
        if ((cw - cropWidth) / 2 + e.changedTouches[0].pageX - mdx + (cw * v - cw) <= 0) {
          x = 0 - (cw - cropWidth) / 2 - (cw * v - cw)
        } else {
          x = (e.changedTouches[0].pageX - mdx);
        }
      }
      drawCanvas();
      return false
    }

    $(range).off('change')
    $(range).on('change', function (e: any) {
      v = parseInt($(e.target).val()?.toString()!);
      drawCanvas();
    })

    imgCropCanvas.removeEventListener("wheel", function () { return false; }, false);
    imgCropCanvas.addEventListener("wheel", function (e: WheelEvent) {
      e.stopPropagation();
      e.preventDefault();
      if (v + e.deltaY / 120 / 100 >= 1) {
        v += e.deltaY / 120 / 100;
        $(range).val(v)
        drawCanvas();
      }
    });
  }
  image.src = image_for_crop

  let crop = (e: any) => {
    imgCropCanvas.setAttribute("width", cropWidth.toString());
    imgCropCanvas.setAttribute("height", cropHeight.toString());
    const ctx = imgCropCanvas.getContext('2d')!;
    ctx.drawImage(image, x - cropX, y, cw * v, ch * v);
    set_src(imgCropCanvas.toDataURL("image/png"))
    set_crop_is_open(false)
  }

  return (
    <div className={`fixed w-full h-full top-0 left-0 bg-black/75 z-10 ${crop_is_open ? 'flex' : 'hidden'} flex-col items-center`}>
      <div className="mt-3 flex justify-center items-center flex-row">
        <input
          type='button'
          className="cursor-pointer block py-2.5 px-4 rounded my-3 border border-solid border-white text-white bg-emerald-700 hover:bg-emerald-900"
          value={t('Back')!}
          onClick={() => { set_crop_is_open(false) }} />

        <input
          type='button'
          className="cursor-pointer block py-2.5 px-4 rounded my-3 border border-solid border-white text-white bg-emerald-700 hover:bg-emerald-900 ml-5"
          value={t('Crop')!}
          onClick={crop} />
      </div>

      <input
        ref={range_ref}
        type='range'
        min='1'
        max='5'
        step='0.5'
        className='block w-11/12 mx-auto mt-3 cursor-pointer' />

      <canvas
        className="block mt-5 cursor-pointer px-3"
        ref={canvas_ref}>
      </canvas>
    </div>
  )
};
