import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import axios from 'axios'

export interface UserType {
  uuid: string,
  id: string,
  username: string,
  email: string,
  sex: string,
  icon: string,
  description: string,
  date_of_birth: string,
  created_at: string,
  twitter: string,
  instagram: string,
  facebook: string,
  line: string,
  is_locked: boolean,
}

export interface UserStateType {
  user: UserType|null,
  status: string,
}

const initialState: UserStateType = {
  user: null,
  status: 'idle'
};

export const updateUserAsync = createAsyncThunk(
  'user/update',
  async (json_token: string) => {
    const token = `Bearer ${JSON.parse(json_token)['access']}`

    type ResponseType = {
      data?: UserType,
    }

    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/user`,
      data: {},
      headers: {
        'Authorization': token,
      },
    })
    .then(async (response: ResponseType) => {
      localStorage.setItem('user', json_token)
      return response.data
    }).catch(async () => {
      localStorage.removeItem('user')
      return false
    })
  }
)

interface update_with_social_account_props {
  social_account_provider: string,
  social_account_access_token: string,
}

export const update_with_social_account = createAsyncThunk(
  'user/update_with_social_account',
  async ({ social_account_provider, social_account_access_token }: update_with_social_account_props) => {
    type ResponseType = {
      data: {
        user: UserType,
        refresh: string,
        access: string,
      }
    }

    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/get_social_account`,
      data: {
        'provider': social_account_provider,
        'access_token': social_account_access_token,
      },
      headers: {},
    })
    .then(async (response: ResponseType) => {
      if (response.data) {
        const token = {
          'refresh': response.data.refresh,
          'access': response.data.access,
        }

        localStorage.setItem('user', JSON.stringify(token))
      } else {
        localStorage.removeItem('user')
      }

      return response.data.user
    }).catch(async () => {
      localStorage.removeItem('user')
      return false
    })
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clear_user: (state) => {
      state.user = initialState.user
      localStorage.removeItem('user')
    },
    update_user: (state, action) => {
      state.user = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // update
      .addCase(updateUserAsync.pending, (state) => {
        state.user = initialState.user
        state.status = 'loading'
      })
      .addCase(updateUserAsync.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.user = action.payload
        } else {
          state.user = initialState.user
          localStorage.removeItem('user')
        }
        state.status = 'idle'
      })
      .addCase(updateUserAsync.rejected, (state) => {
        state.user = initialState.user
        state.status = 'failed'
        localStorage.removeItem('user')
      })

      // update_with_social_account
      .addCase(update_with_social_account.pending, (state) => {
        state.user = initialState.user
        state.status = 'loading'
      })
      .addCase(update_with_social_account.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.user = action.payload
        } else {
          state.user = initialState.user
          localStorage.removeItem('user')
        }
        state.status = 'idle'
      })
      .addCase(update_with_social_account.rejected, (state) => {
        state.user = initialState.user
        state.status = 'failed'
        localStorage.removeItem('user')
      })
  },
})

export const get_user = (state: RootState) => state.user.user;

export const {
  clear_user,
  update_user,
} = userSlice.actions

export default userSlice.reducer;
