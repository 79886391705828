import React, {
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from '../../app/hooks'
import {
  useNavigate,
} from 'react-router-dom'

import {
  get_user,
  update_user,
} from '../../slice/userSlice'

import {
  custom_axios,
} from '../../axios'

export const Password = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(get_user)

  const [password_not_match, set_password_not_match] = useState(false)

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);
    const form_value = Object.fromEntries(form_data)

    if (form_value['new_password'] !== form_value['new_password_confirmation']) {
      set_password_not_match(true)
      return
    } else {
      set_password_not_match(false)
    }

    custom_axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/setting/password`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response.data.user) {
        dispatch(update_user(response.data.user))
        navigate(`/${i18n.language}`)
      } else {
        alert(t("Password is incorrect"))
      }
    })
    .catch((response) => {
      alert(t('Error'))
    })
  }

  if (user) {
    return (
      <section className='p-2.5 lg:p-5'>
        <form
          onSubmit={ submit }
          method="post">

          <h3 className="text-center text-sky-400 mb-2.5">
            { t('Password') }
          </h3>

          <input
            required
            type='password'
            className="block text-center p-2 bg-white border border-black border-solid text-black w-full"
            name='password' />

          <h3 className="text-center text-sky-400 mt-6 mb-2.5">
            { t('New password') }
          </h3>

          <input
            required
            type='password'
            className="block text-center p-2 bg-white border border-black border-solid text-black w-full"
            name='new_password' />

          <h3 className="text-center text-sky-400 mt-5 mb-2">
            { t('New password confirmation') }
          </h3>

          <div className={`text-rose-400 mb-1 ${ password_not_match ? 'block' : 'hidden' }`}>
            { t('Password confirmation does not match') }
          </div>
          <input
            required
            type='password'
            className="block text-center p-2 bg-white border border-black border-solid text-black w-full"
            name='new_password_confirmation' />

          <input
            type='submit'
            className="bg-rose-800 border-black border rounded block px-6 py-3 mx-auto mt-5 cursor-pointer text-white shadow-black shadow hover:bg-rose-600 active:translate-y-0.5 active:shadow-none"
            value={ t('Submit')! } />
        </form>
      </section>
    )
  } else {
    return (
      <h2 className='text-center text-red-300 mt-4'>{ t('Please login first') }</h2>
    )
  }
}