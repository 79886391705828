import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface PostStateType {
  form_is_visible: boolean,
  status: string,
}

const initialState: PostStateType = {
  form_is_visible: false,
  status: 'idle'
};

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    update_form_is_visible: (state, action) => {
      state.form_is_visible = action.payload
    },
  },
  extraReducers: (builder) => {},
})

export const get_form_is_visible = (state: RootState) => state.post.form_is_visible

export const {
  update_form_is_visible,
} = postSlice.actions

export default postSlice.reducer;
